// store.js
import { reactive } from 'vue';

export const store = reactive({
	config: {},
	adsList: [],
	adsIdConfig: {
		'/home': '1,5,6,11,12',
		'/details': '1,3,4,6,9,11,13',
		'/more': '1,6,11',
		'/search': '6,11'
	},
	setConfig: (config) => {
		store.config = config;
	},
	setAdsList: (value) => {
		value.forEach((v) => {
			if (store.isDev()) {
				// v.icon = require('@/assets/logo.png')
				v.name = 'name';
			}
		});
		store.adsList = value;
	},
	isDev: () => {
		// return process.env.NODE_ENV === "development"
		return false;
	},
	homeIndexCurrent: 1,
	setHomeIndexCurrent: (value) => {
		store.homeIndexCurrent = value;
	}
});
